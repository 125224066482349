<template>
  <div class="base">
    <header>
      <div id="navbar">
        <div class="container">
          <div class="brand">
            <img src="~@/assets/img/icon.svg" alt="iconn" class="icon" />
            <div class="text">长城云盾</div>
          </div>
          <ul class="menus">
            <li class="menu-item" v-for="(item, index) in display.navbarMenus" :key="index">
              <a :href="item.link" class="menu-link"> {{ item.title }} </a>
            </li>
          </ul>
          <div class="signup">
            <router-link to="/auth/login" class="btn btn-sm btn-success"> 登录 </router-link>
            <router-link to="/auth/signup" class="btn btn-sm btn-primary"> 免费注册 </router-link>
          </div>
        </div>
      </div>
    </header>
    <div id="container">
      <router-view />
    </div>
    <footer>
      <div class="container">
        <div class="copyright">&copy; {{ new Date().getFullYear() }} 长城云盾安全版权所有</div>
        <ul class="links">
          <li class="link-item" v-for="(item, index) in display.footerLinks" :key="index">
            <a :href="item.link" class="link">
              {{ item.title }}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Base",
  data() {
    return {
      display: {
        navbarMenus: [
          {
            title: "首页",
            link: "/",
          },
          {
            title: "最新活动",
            link: "/",
          },
          {
            title: "商业授权",
            link: "/",
          },
          {
            title: "关于",
            link: "/",
          },
        ],
        footerLinks: [
          {
            title: "粤ICP备2021002714号",
            link: "https://beian.miit.gov.cn/",
          },
          {
            title: "法律声明",
            link: "/",
          },
          {
            title: "隐私协议",
            link: "/",
          },
          {
            title: "违规站点反馈",
            link: "/report",
          },
          {
            title: "中国互联网举报入口",
            link: "https://www.12377.cn/",
          },
        ],
      },
    }
  },
  components: {},
}
</script>

<style lang="scss">
@import "~@/assets/scss/init";
@import "~@/assets/scss/layouts/base";

.base {
  //background: $backgroundColor;

  header {
    //

    #navbar {
      background: white;

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $navbarHeight;
      }

      .brand {
        display: flex;
        align-items: center;

        .icon {
          width: $space * 1.5;
          margin-right: $space;
        }

        .text {
          font-weight: bolder;
          color: darken($fontColor, 25);
        }
      }

      .menus {
        margin: 0;
        padding: 0;
        list-style: none;

        .menu-item {
          float: left;

          .menu-link {
            display: block;
            color: $fontColor;
            padding: 0 ($space * 2);

            @include commonHoverAndActive;
          }
        }
      }

      .signup {
        //

        .btn {
          border: unset;

          &:first-child {
            border-radius: $borderRadius 0 0 $borderRadius;
          }

          &:last-child {
            border-radius: 0 $borderRadius $borderRadius 0;
          }
        }
      }
    }
  }

  #container {
    min-height: calc(100vh - #{$navbarHeight + $footerHeight});
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $footerHeight;
    text-align: center;
    font-size: $space * 0.85;
    //background: darken($backgroundColor, 2.5);
    background: $backgroundColor;

    .copyright {
      //color: $fontColor;
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      list-style: none;

      .link-item {
        //

        .link {
          display: block;
          padding: ($space / 2) ($space / 2) 0;
          color: lighten($fontColor, 25);

          @include commonHoverAndActive;
        }
      }
    }
  }
}
</style>
